/**
 * 404 error page
 */

// Import statements
import React from "react"
import {graphql} from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/common/seo"

/**
 * Define the NotFoundPage constant
 * @param data
 * @param location
 * @returns {JSX.Element}
 * @constructor
 */
const NotFoundPage = ({data, location}) => {

    // define the siteTitle of this page
    const siteTitle = data.site.siteMetadata.title

    // return the information
    return (
        <Layout location={location} title={siteTitle}>
            <Seo title="404: Not Found"/>
            <div className="container text-center my-5">
                <h1>404: Not Found</h1>
                <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
            </div>
        </Layout>
    )
}

/**
 * Export the NotFoundPage variable
 */
export default NotFoundPage

/**
 * Get the website title with GraphQL
 * @type {StaticQueryDocument}
 */
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
